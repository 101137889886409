/* global __VUE_PROD_DEVTOOLS__ */

/**
 * @param {import('vue').App} app
 * @param {typeof import('./auth').auth} auth
 */
export async function setupDevtools (app, auth) {
  // Only setup the plugin in development mode or if the Vue Devtools is enabled in production
  if (process.env.NODE_ENV !== 'development' && !__VUE_PROD_DEVTOOLS__) {
    return
  }

  const { setupDevtoolsPlugin } = await import('@vue/devtools-api')
  const { watch } = await import('vue')

  setupDevtoolsPlugin({
    id: 'auth-devtools-plugin',
    label: 'Max Auth Plugin',
    homepage: 'https://my.max.auto',
    logo: 'https://my.max.auto/apple-touch-icon.png',
    app
  }, api => {
    const inspectorId = 'auth-plugin-inspector'
    const authStateChangeTimelineId = 'auth-state-change-timeline'

    api.addInspector({
      id: inspectorId,
      label: 'Max Auth Plugin',
      icon: 'key'
    })

    api.on.getInspectorTree(payload => {
      if (payload.inspectorId !== inspectorId) return

      payload.rootNodes = [
        {
          id: 'auth',
          label: 'Auth'
        }
      ]
    })

    api.on.getInspectorState(payload => {
      if (payload.inspectorId !== inspectorId) return
      if (payload.nodeId !== 'auth') return

      const allProperties = Object.getOwnPropertyDescriptors(auth)
      const state = Object.entries(allProperties)
        .filter(([, descriptor]) => descriptor.writable && typeof descriptor.value !== 'function')
        .map(([key]) => ({
          key,
          value: auth[key]
        }))

      const computed = Object.entries(allProperties)
        .filter(([, descriptor]) => !descriptor.writable && typeof descriptor.get === 'function')
        .map(([key]) => ({
          key,
          value: auth[key]
        }))

      payload.state = {
        state,
        computed
      }

      // Sets the state to be used in the console tab just like $vm in Vue components
      // This will only be set if the plugin is opened
      window.$auth = auth
    })

    api.addTimelineLayer({
      id: authStateChangeTimelineId,
      color: 0xf26522,
      label: 'Auth State Change'
    })

    watch(auth, value => {
      api.sendInspectorState(inspectorId)

      api.addTimelineEvent({
        layerId: authStateChangeTimelineId,
        event: {
          time: api.now(),
          data: value
        }
      })
    }, { deep: true })
  })
}
