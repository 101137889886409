import photoRoutes from '../photos/routes.js'

export default [
  {
    component: () => import(
      /* webpackChunkName: "appraisals" */
      './Manager.vue'
    ),
    meta: { title: 'Appraisals' },
    name: 'appraisal.manager',
    path: '/appraisals'
  },

  {
    component: () => import(
      /* webpackChunkName: "appraisals" */
      './LostCustomers/index.vue'
    ),
    name: 'appraisal.lost-customers',
    path: '/appraisals/lost-customers'
  },

  ...photoRoutes({
    name: 'appraisal.details.photos.embed',
    path: '/appraisals/:id/photos/embed',
    meta: { layout: 'none' },
    defaultTab: 'appraisal'
  }),

  {
    component: () => import(
      /* webpackChunkName: "appraisals" */
      './AppraisalDetails/index.vue'
    ),
    name: 'appraisal.details',
    path: '/appraisals/:id',
    meta: { fullscreen: true },
    children: [
      ...photoRoutes({
        name: 'appraisal.details.photos',
        path: 'photos',
        defaultTab: 'appraisal',
        meta: { fullscreen: false }
      }),
      {
        component: () => import(
          /* webpackChunkName: "appraisals" */
          './Disclosures/index.vue'
        ),
        name: 'appraisal.details.disclosures',
        path: 'disclosures',
        meta: { fullscreen: false }
      }
    ].filter(Boolean)
  }
]
