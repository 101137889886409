<script lang="jsx">
import { computed, getCurrentInstance, h, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  VApp,
  VMain,
  VSnackbar
} from 'vuetify/components'

import * as layouts from './layouts'
import { useAuth } from './plugins/auth'
import { registeredDialogs } from '~/ui/elements/ProgrammaticDialog'

export default {
  name: 'LilypadApp',
  setup () {
    const snackMessage = ref(null)

    const showNotification = text => {
      snackMessage.value = text
    }

    const auth = useAuth()
    const route = useRoute()
    const { appContext } = getCurrentInstance()

    // Providing so the Composition API has access
    provide('showNotification', showNotification)
    // Adding to globalProperties so templates and the Options API have access
    appContext.config.globalProperties.$showNotification = showNotification

    const Layout = computed(() => {
      if (auth.loading) return layouts.loading
      if (auth.hasError) return layouts.error

      // In some cases we want routes to be rendered without the default layout.
      // An example of this is the embeddable pricing tab.
      if (route.meta?.layout === 'none') return layouts.embed

      return layouts.default
    })

    return () => (
      <VApp>
        <VMain>
          <Layout.value />
        </VMain>
        <VSnackbar
          modelValue={Boolean(snackMessage.value)}
          timeout={4000}
          onUpdate:modelValue={() => { snackMessage.value = null }}
        >
          {snackMessage.value}
        </VSnackbar>
        {Object.values(registeredDialogs.value).map(dialog => h(dialog))}
      </VApp>
    )
  }
}
</script>

<style lang="scss">
@use '~/styles/mixins' as *;

html.layout--fullscreen {
  overflow-y: hidden;
}

.v-main > .vue-portal-target > * {
  @include z-index(dialog);
}

// Updates to the VTooltip global styling based on Material specs.
// @see https://material.io/components/tooltips#specs
.v-tooltip .v-overlay__content {
  font-size: 10px;
  line-height: 24px;
  padding: 0 8px;
}

[aria-busy="true"],
.v-application a[aria-busy="true"] {
  cursor: progress;
  pointer-events: all;
}
</style>
