<script>

import features from '~/plugins/features'

export default {
  name: 'FeaturePreviewDialog',
  setup () {
    const getFlag = flagName => features.has(flagName)
    const setFlag = (flagName, value) => {
      value ? features.enableFeature(flagName) : features.disableFeature(flagName)
    }

    return {
      features,
      getFlag,
      setFlag
    }
  }
}
</script>

<template>
  <VCard class="feature-preview">
    <VCardTitle class="d-flex text-h5">
      Feature preview
      <VSpacer />
      <VBtn
        icon
        variant="text"
        density="comfortable"
        @click="$emit('close', true)"
      >
        <VIcon>$close</VIcon>
      </VBtn>
    </VCardTitle>
    <VDivider />
    <VList
      variant="flat"
      lines="two"
    >
      <VListItem
        v-for="(feature, key) in features.available"
        :key="key"
        class="pl-6 pr-7"
      >
        <template #default>
          <VListItemTitle>{{ feature.title }}</VListItemTitle>
          <VListItemSubtitle>
            {{ feature.subtitle }}
          </VListItemSubtitle>
        </template>
        <template #append>
          <VListItemAction>
            <VSwitch
              class="feature-preview__switch"
              :model-value="getFlag(key)"
              color="success"
              hide-details
              inset
              @update:model-value="setFlag(key, $event)"
            />
          </VListItemAction>
        </template>
      </VListItem>
    </VList>
  </VCard>
</template>

<style lang="scss">
  .feature-preview {
    &__switch {
      .v-switch__track {
        opacity: unset;
      }
    }
  }
</style>
