import { PortalTarget } from 'portal-vue'
import { h } from 'vue'
import { RouterView } from 'vue-router'

export default function EmbedLayout () {
  return [
    h(RouterView, { class: 'layout--none' }),
    h(PortalTarget, { name: 'after-footer' })
  ]
}
